/* Base styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom base styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-gray-800;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

/* Custom components using theme colors */
@layer components {
  .btn-primary {
    @apply px-6 py-3 bg-primary text-white rounded-full hover:bg-secondary 
           transition-all duration-300 transform hover:scale-105 animate-fade-in;
  }
  
  .card {
    @apply p-6 bg-white rounded-xl shadow-lg hover:shadow-xl 
           transition-all duration-300 animate-slide-up;
  }

  .section-padding {
    @apply py-16 md:py-24;
  }

  .gradient-text {
    @apply bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text;
  }

  .header {
    @apply bg-gray-50 py-4 text-center border-b border-gray-200;
  }

  .nav-list {
    @apply list-none m-0 p-0 inline-block;
  }

  .nav-list li {
    @apply inline-block mr-5;
  }

  .nav-list li a {
    @apply no-underline text-gray-700 font-bold hover:text-primary transition-colors duration-300;
  }
}

/* Hero section gradient */
.hero-gradient {
  background: linear-gradient(135deg, #f0fff4 0%, #ffffff 100%);
}

/* Feature icon styling */
.feature-icon {
  filter: drop-shadow(0 0 8px rgba(44, 152, 63, 0.2));
}

/* Editor styles with theme colors */
.ql-editor {
  @apply text-gray-700 leading-relaxed mx-auto text-lg min-h-[400px];
}

/* Heading Styles */
.ql-editor h1 {
  @apply text-3xl font-bold mt-8 mb-6 text-primary border-b-2 border-primary/20 pb-3;
}

.ql-editor h2 {
  @apply text-2xl font-bold mt-6 mb-4 text-primary border-b border-primary/10 pb-2;
}

.ql-editor h3 {
  @apply text-xl font-bold mt-5 mb-3 text-primary/90;
}

/* Content styles */
.ql-editor p {
  @apply text-gray-700 leading-relaxed mb-6 text-lg;
}

.ql-editor strong {
  @apply text-primary font-semibold;
}

.ql-editor em {
  @apply text-primary/90;
}

/* Links */
.ql-editor a {
  @apply text-primary underline decoration-2 decoration-primary/20
         transition-all duration-300 hover:text-secondary hover:decoration-secondary/50;
}

/* Lists */
.ql-editor ul {
  @apply space-y-2 mb-6 ml-6;
}

.ql-editor ul li {
  @apply relative pl-6;
}

.ql-editor ul li::before {
  content: "";
  @apply absolute left-0 top-3 w-2 h-2 bg-primary rounded-full;
}

/* Blockquotes */
.ql-editor blockquote {
  @apply border-l-4 border-primary pl-6 py-4 my-6 bg-primary/5 
         rounded-r-lg text-primary/90 italic shadow-sm;
}

/* Code blocks */
.ql-editor pre {
  @apply bg-primary/5 rounded-xl p-6 my-6 border border-primary/10 
         shadow-inner font-mono text-primary/90 text-sm overflow-x-auto;
}

.ql-editor code {
  @apply bg-primary/5 rounded px-2 py-1 font-mono text-sm text-primary/90 
         border border-primary/10;
}

/* ReactQuill toolbar styling */
.ql-toolbar.ql-snow {
  @apply border-primary/20 rounded-t-lg bg-primary/5 sticky top-0 z-10;
}

.ql-container.ql-snow {
  @apply border-primary/20 rounded-b-lg min-h-[400px];
}

/* Toolbar button states */
.ql-toolbar button:hover,
.ql-toolbar button:focus {
  @apply text-primary;
}

.ql-toolbar button.ql-active {
  @apply text-secondary;
}

/* Divider styling */
.ql-editor hr {
  @apply my-8 border-t-2 border-primary/10;
}

/* Animate elements */
.animate-fade {
  @apply animate-fade-in;
}

.animate-slide {
  @apply animate-slide-up;
}

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}